import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "300",
      "style": {
        "position": "relative"
      }
    }}>{`3.0.0`}</h1>
    <p>{`3.0.0 is a pretty big new major version with lots of (breaking) changes.`}</p>
    <h2 {...{
      "id": "the-rename",
      "style": {
        "position": "relative"
      }
    }}>{`The rename`}</h2>
    <p>{`One of the biggest breaking changes is the renaming of some of our
chameleon-theme-`}{`*`}{` packages. This is the full list of the theme packages that
have been renamed.`}</p>
    <ul>
      <li parentName="ul">{`chameleon-theme-gva-summer-2020 -> chameleon-theme-gva`}</li>
      <li parentName="ul">{`chameleon-theme-hbvl-summer-2020 -> chameleon-theme-hbvl`}</li>
      <li parentName="ul">{`chameleon-theme-hmc-ge -> chameleon-theme-ge`}</li>
      <li parentName="ul">{`chameleon-theme-hmc-hd -> chameleon-theme-hd`}</li>
      <li parentName="ul">{`chameleon-theme-hmc-ld -> chameleon-theme-ld`}</li>
      <li parentName="ul">{`chameleon-theme-hmc-nhd -> chameleon-theme-nhd`}</li>
      <li parentName="ul">{`chameleon-theme-hmc-yc -> chameleon-theme-yc`}</li>
      <li parentName="ul">{`chameleon-theme-nb -> chameleon-theme-nb-legacy`}</li>
      <li parentName="ul">{`chameleon-theme-nb-winter-2021 -> chameleon-theme-nb`}</li>
      <li parentName="ul">{`chameleon-theme-swcom -> chameleon-theme-sw`}</li>
      <li parentName="ul">{`chameleon-theme-wort-de -> chameleon-theme-lw`}</li>
      <li parentName="ul">{`chameleon-theme-wort-en -> chameleon-theme-lt`}</li>
      <li parentName="ul">{`chameleon-theme-wort-pt -> chameleon-theme-co`}</li>
      <li parentName="ul">{`chameleon-theme-wort-tc -> chameleon-theme-tc`}</li>
    </ul>
    <p><strong parentName="p">{`To migrate:`}</strong>{` rename these packages in your dependencies`}</p>
    <h2 {...{
      "id": "chameleon-react-updates",
      "style": {
        "position": "relative"
      }
    }}>{`chameleon-react updates`}</h2>
    <p>{`The breaking changes in our React components are:`}</p>
    <h3 {...{
      "id": "autocomplete",
      "style": {
        "position": "relative"
      }
    }}>{`AutoComplete`}</h3>
    <p>{`The already deprecated `}<inlineCode parentName="p">{`onSuggestionSelected`}</inlineCode>{` prop is now removed.`}</p>
    <p><strong parentName="p">{`To migrate:`}</strong>{` use the `}<inlineCode parentName="p">{`onSelect`}</inlineCode>{` prop instead`}</p>
    <h3 {...{
      "id": "choice",
      "style": {
        "position": "relative"
      }
    }}>{`Choice`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`caption`}</inlineCode>{` prop has been renamed to `}<inlineCode parentName="p">{`message`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`To migrate:`}</strong>{` Update your implementations from `}<inlineCode parentName="p">{`<Choice caption="..." />`}</inlineCode>{` to
`}<inlineCode parentName="p">{`<Choice message="..." />`}</inlineCode></p>
    <h3 {...{
      "id": "iconbutton",
      "style": {
        "position": "relative"
      }
    }}>{`IconButton`}</h3>
    <p>{`The already deprecated `}<inlineCode parentName="p">{`name`}</inlineCode>{` prop is now removed`}</p>
    <p><strong parentName="p">{`To migrate:`}</strong>{` Update your implementations from `}<inlineCode parentName="p">{`<IconButton name="..." />`}</inlineCode>{` to
`}<inlineCode parentName="p">{`<IconButton icon={...} />`}</inlineCode></p>
    <h3 {...{
      "id": "image",
      "style": {
        "position": "relative"
      }
    }}>{`Image`}</h3>
    <p>{`The already deprecated `}<inlineCode parentName="p">{`<Image />`}</inlineCode>{` component is now removed`}</p>
    <p><strong parentName="p">{`To migrate:`}</strong>{` Use the native HTML `}<inlineCode parentName="p">{`<img />`}</inlineCode>{` tag.`}</p>
    <h3 {...{
      "id": "datepicker",
      "style": {
        "position": "relative"
      }
    }}>{`DatePicker`}</h3>
    <p>{`We fixed the `}<inlineCode parentName="p">{`readOnly`}</inlineCode>{` prop on `}<inlineCode parentName="p">{`<DatePicker />`}</inlineCode>{`: it was only limiting the
manual input on the input field, but edits made through the picker dialog were
still possible. Setting the `}<inlineCode parentName="p">{`readOnly`}</inlineCode>{` prop now creates a 100% read-only input.
The previous behavior is moved to a new `}<inlineCode parentName="p">{`pickerInputOnly`}</inlineCode>{` prop.`}</p>
    <p><strong parentName="p">{`To migrate:`}</strong>{` Do nothing, unless you really want the old `}<inlineCode parentName="p">{`readOnly`}</inlineCode>{` behavior.
In that case, update your implementations from `}<inlineCode parentName="p">{`<DatePicker readOnly />`}</inlineCode>{` to
`}<inlineCode parentName="p">{`<DatePicker pickerInputOnly />`}</inlineCode></p>
    <h3 {...{
      "id": "widelist",
      "style": {
        "position": "relative"
      }
    }}>{`WideList`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`clickable`}</inlineCode>{` prop no longer adds a `}<inlineCode parentName="p">{`<ChevronForward />`}</inlineCode>{` icon by default to
show that it is clickable.`}</p>
    <p><strong parentName="p">{`To migrate:`}</strong>{` Update your implementations from `}<inlineCode parentName="p">{`<WideListItem clickable />`}</inlineCode>{` to
`}<inlineCode parentName="p">{`<WideListItem clickable iconRight={ChevronForward} />`}</inlineCode></p>
    <p>{`The `}<inlineCode parentName="p">{`icon`}</inlineCode>{` prop is removed and 2 new props are added: `}<inlineCode parentName="p">{`iconLeft`}</inlineCode>{` and
`}<inlineCode parentName="p">{`iconRight`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`To migrate:`}</strong>{` Update your implementations from `}<inlineCode parentName="p">{`<WideListItem icon={Add} />`}</inlineCode>{`
to `}<inlineCode parentName="p">{`<WideListItem iconLeft={Add} />`}</inlineCode>{` or
`}<inlineCode parentName="p">{`<WideListItem iconLeft={<Icon as={Add} />} />`}</inlineCode></p>
    <p>{`The `}<inlineCode parentName="p">{`iconColor`}</inlineCode>{` prop is removed.`}</p>
    <p><strong parentName="p">{`To migrate:`}</strong>{` Update your implementations from
`}<inlineCode parentName="p">{`<WideListItem icon={Add} iconColor="primaryBase" />`}</inlineCode>{` to
`}<inlineCode parentName="p">{`<WideListItem iconLeft={<Icon as={Add} color="primaryBase" />} />`}</inlineCode></p>
    <h2 {...{
      "id": "chameleon-swift-updates",
      "style": {
        "position": "relative"
      }
    }}>{`chameleon-swift updates`}</h2>
    <p>{`Starting with this release, we provide an implementation created specifically
for Swift. The `}<inlineCode parentName="p">{`lib/ios`}</inlineCode>{` folder in each `}<inlineCode parentName="p">{`chameleon-theme-*`}</inlineCode>{` NPM package has been
deleted.`}</p>
    <p><strong parentName="p">{`To migrate:`}</strong>{` Use the dedicated
`}<a parentName="p" {...{
        "href": "https://gitlab.mediahuisgroup.com/frontend/chameleon-swift"
      }}>{`chameleon-swift`}</a>{`
package.`}</p>
    <hr></hr>
    <h1 {...{
      "id": "200",
      "style": {
        "position": "relative"
      }
    }}>{`2.0.0`}</h1>
    <p>{`We've completely overhauled our CSS architecture in `}<inlineCode parentName="p">{`chameleon-react`}</inlineCode>{` and now
use vanilla `}<inlineCode parentName="p">{`CSS`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`SCSS`}</inlineCode>{`. This also means we now use CSS Custom
Properties instead of SCSS variables! (An SCSS output of all design tokens still
remains available for you to use for the time being.) To be able to use it, you
now have to include a little
`}<a parentName="p" {...{
        "href": "/getting-started/for-developers/#h-postcss-configuration-required"
      }}>{`PostCSS configuration`}</a>{`
to make it work.`}</p>
    <h2 {...{
      "id": "components",
      "style": {
        "position": "relative"
      }
    }}>{`Components`}</h2>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`<Fonts />`}</inlineCode>{` component has been removed. Please import
`}<inlineCode parentName="li">{`@mediahuis/chameleon-theme-wl/lib/web/fonts.css`}</inlineCode>{` instead of using this
component.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`<Reset />`}</inlineCode>{` component has been removed. Please import
`}<inlineCode parentName="li">{`@mediahuis/chameleon-reset`}</inlineCode>{` instead of using this component.`}</li>
    </ul>
    <hr></hr>
    <h1 {...{
      "id": "1130",
      "style": {
        "position": "relative"
      }
    }}>{`1.13.0`}</h1>
    <h2 {...{
      "id": "components-1",
      "style": {
        "position": "relative"
      }
    }}>{`Components`}</h2>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`<Fonts />`}</inlineCode>{` component has been removed. Please import
`}<inlineCode parentName="li">{`@mediahuis/chameleon-theme-wl/lib/web/fonts.css`}</inlineCode>{` instead of using this
component.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`<Reset />`}</inlineCode>{` component has been removed. Please import
`}<inlineCode parentName="li">{`@mediahuis/chameleon-reset`}</inlineCode>{` instead of using this component.`}</li>
    </ul>
    <h1 {...{
      "id": "190",
      "style": {
        "position": "relative"
      }
    }}>{`1.9.0`}</h1>
    <h2 {...{
      "id": "components-2",
      "style": {
        "position": "relative"
      }
    }}>{`Components`}</h2>
    <p>{`Import @mediahuis/chameleon-reset instead of using this component. For more
information see:
`}<a parentName="p" {...{
        "href": "https://dev.azure.com/mediahuis/DNM/_packaging?_a=package&feed=pulse&package=%40mediahuis%2Fchameleon-reset&protocolType=Npm"
      }}>{`chameleon-reset`}</a></p>
    <h1 {...{
      "id": "180",
      "style": {
        "position": "relative"
      }
    }}>{`1.8.0`}</h1>
    <h2 {...{
      "id": "components-3",
      "style": {
        "position": "relative"
      }
    }}>{`Components`}</h2>
    <h3 {...{
      "id": "autocomplete-1",
      "style": {
        "position": "relative"
      }
    }}>{`Autocomplete`}</h3>
    <pre><code parentName="pre" {...{}}>{`<AutoComplete onSuggestionSelected={e => console.log(e.target.value)} /> -> <AutoComplete onSelect={selectedItem => console.log(selectedItem)} />
`}</code></pre>
    <h1 {...{
      "id": "170",
      "style": {
        "position": "relative"
      }
    }}>{`1.7.0`}</h1>
    <h2 {...{
      "id": "components-4",
      "style": {
        "position": "relative"
      }
    }}>{`Components`}</h2>
    <h3 {...{
      "id": "illustration",
      "style": {
        "position": "relative"
      }
    }}>{`Illustration`}</h3>
    <p>{`Import the used illustration from `}<inlineCode parentName="p">{`chameleon-theme-wl/illustration`}</inlineCode>{`. Replace
name with as (the imported illustration).`}</p>
    <pre><code parentName="pre" {...{}}>{`import { PageNotFound } from '@mediahuis/chameleon-theme-wl/illustrations;'

<Illustration name="PageNotFound" /> -> <Illustration as={PageNotFound} />
`}</code></pre>
    <h1 {...{
      "id": "150",
      "style": {
        "position": "relative"
      }
    }}>{`1.5.0`}</h1>
    <h2 {...{
      "id": "components-5",
      "style": {
        "position": "relative"
      }
    }}>{`Components`}</h2>
    <h3 {...{
      "id": "icon",
      "style": {
        "position": "relative"
      }
    }}>{`Icon`}</h3>
    <p>{`Import the used icon from `}<inlineCode parentName="p">{`chameleon-theme-wl/icons`}</inlineCode>{`. Replace name with as (the
imported icon).`}</p>
    <pre><code parentName="pre" {...{}}>{`import { Add } from '@mediahuis/chameleon-theme-wl/icons;'

<Icon name="Add" /> -> <Icon as={Add} />
`}</code></pre>
    <h3 {...{
      "id": "iconbutton-1",
      "style": {
        "position": "relative"
      }
    }}>{`IconButton`}</h3>
    <p>{`Import the used icon from `}<inlineCode parentName="p">{`chameleon-theme-wl/icons`}</inlineCode>{`. Replace name with icon
(the imported icon).`}</p>
    <pre><code parentName="pre" {...{}}>{`import { Add } from '@mediahuis/chameleon-theme-wl/icons;'

<IconButton name="Add" /> -> <IconButton icon={Add} />
`}</code></pre>
    <h3 {...{
      "id": "button",
      "style": {
        "position": "relative"
      }
    }}>{`Button`}</h3>
    <p>{`Import the used icon from `}<inlineCode parentName="p">{`chameleon-theme-wl/icons`}</inlineCode>{`. Replace iconLeft and
iconRight with the imported icon.`}</p>
    <pre><code parentName="pre" {...{}}>{`import { Add } from '@mediahuis/chameleon-theme-wl/icons;'

<Button iconLeft="Add" iconRight="Add" /> -> <Button iconLeft={Add} iconRight={Add} />
`}</code></pre>
    <h3 {...{
      "id": "textfield-select",
      "style": {
        "position": "relative"
      }
    }}>{`TextField, Select`}</h3>
    <p>{`Import the used icon from `}<inlineCode parentName="p">{`chameleon-theme-wl/icons`}</inlineCode>{`. Replace iconLeft with the
imported icon.`}</p>
    <pre><code parentName="pre" {...{}}>{`import { Add } from '@mediahuis/chameleon-theme-wl/icons;'

<TextField iconLeft="Add" /> -> <TextField iconLeft={Add} />
<Select iconLeft="Add" /> -> <Select iconLeft={Add} />
`}</code></pre>
    <h3 {...{
      "id": "autocomplete-listitem-widelistitem",
      "style": {
        "position": "relative"
      }
    }}>{`AutoComplete, ListItem, WideListItem`}</h3>
    <p>{`Import the used icon from `}<inlineCode parentName="p">{`chameleon-theme-wl/icons`}</inlineCode>{`. Replace icon with the
imported icon.`}</p>
    <pre><code parentName="pre" {...{}}>{`import { Add } from '@mediahuis/chameleon-theme-wl/icons;'

<AutoComplete icon="Add" /> -> <AutoComplete icon={Add} />
<WideListItem icon="Add" /> -> <WideListItem icon={Add} />
<ListItem icon="Add" /> -> <ListItem icon={Add} />
`}</code></pre>
    <h1 {...{
      "id": "100",
      "style": {
        "position": "relative"
      }
    }}>{`1.0.0`}</h1>
    <h2 {...{
      "id": "components-6",
      "style": {
        "position": "relative"
      }
    }}>{`Components`}</h2>
    <h3 {...{
      "id": "autocomplete-2",
      "style": {
        "position": "relative"
      }
    }}>{`AutoComplete`}</h3>
    <ul>
      <li parentName="ul">{`Switched to a controlled component. This means that the `}<inlineCode parentName="li">{`value`}</inlineCode>{` prop is now
`}<strong parentName="li">{`required`}</strong>{` but can thus be cleared if needed.`}</li>
      <li parentName="ul">{`Removed `}<inlineCode parentName="li">{`defaultValue`}</inlineCode>{` prop as this is now controlled by `}<inlineCode parentName="li">{`value`}</inlineCode>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "checkbox",
      "style": {
        "position": "relative"
      }
    }}>{`Checkbox`}</h3>
    <ul>
      <li parentName="ul">{`Removed `}<inlineCode parentName="li">{`experimentalLabelProps`}</inlineCode>{` in favor of `}<inlineCode parentName="li">{`labelProps`}</inlineCode>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "dialog",
      "style": {
        "position": "relative"
      }
    }}>{`Dialog`}</h3>
    <ul>
      <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`closeButtonLabel`}</inlineCode>{` to `}<inlineCode parentName="li">{`accessibilityLabelCloseButton`}</inlineCode>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "fieldset",
      "style": {
        "position": "relative"
      }
    }}>{`Fieldset`}</h3>
    <ul>
      <li parentName="ul">{`Removed `}<inlineCode parentName="li">{`formId`}</inlineCode>{` in favor of the native `}<inlineCode parentName="li">{`form`}</inlineCode>{` prop.`}</li>
    </ul>
    <h3 {...{
      "id": "iconbutton-2",
      "style": {
        "position": "relative"
      }
    }}>{`IconButton`}</h3>
    <ul>
      <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`label`}</inlineCode>{` to `}<inlineCode parentName="li">{`accessibilityLabel`}</inlineCode>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "listitem",
      "style": {
        "position": "relative"
      }
    }}>{`ListItem`}</h3>
    <ul>
      <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`label`}</inlineCode>{` to `}<inlineCode parentName="li">{`accessibilityLabel`}</inlineCode>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "loader",
      "style": {
        "position": "relative"
      }
    }}>{`Loader`}</h3>
    <ul>
      <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`label`}</inlineCode>{` to `}<inlineCode parentName="li">{`accessibilityLabel`}</inlineCode>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "radio",
      "style": {
        "position": "relative"
      }
    }}>{`Radio`}</h3>
    <ul>
      <li parentName="ul">{`Removed `}<inlineCode parentName="li">{`experimentalLabelProps`}</inlineCode>{` in favor of `}<inlineCode parentName="li">{`labelProps`}</inlineCode>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "stepper",
      "style": {
        "position": "relative"
      }
    }}>{`Stepper`}</h3>
    <ul>
      <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`activeLabel`}</inlineCode>{` to `}<inlineCode parentName="li">{`accessibilityLabelActive`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`completedLabel`}</inlineCode>{` to `}<inlineCode parentName="li">{`accessibilityLabelCompleted`}</inlineCode>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "switch",
      "style": {
        "position": "relative"
      }
    }}>{`Switch`}</h3>
    <ul>
      <li parentName="ul">{`Removed `}<inlineCode parentName="li">{`experimentalLabelProps`}</inlineCode>{` in favor of `}<inlineCode parentName="li">{`labelProps`}</inlineCode>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "textfield",
      "style": {
        "position": "relative"
      }
    }}>{`TextField`}</h3>
    <ul>
      <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`clearButtonLabel`}</inlineCode>{` to `}<inlineCode parentName="li">{`accessibilityLabelClearButton`}</inlineCode>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "tokens",
      "style": {
        "position": "relative"
      }
    }}>{`Tokens`}</h2>
    <p>{`Most of these tokens were deprecated for a few minor versions already. This
release, we're effectively removing them.`}</p>
    <h3 {...{
      "id": "border-radius",
      "style": {
        "position": "relative"
      }
    }}>{`Border-radius`}</h3>
    <pre><code parentName="pre" {...{}}>{`
border-radius-x-small -> border-radius-1 border-radius-small -> border-radius-2
border-radius-medium -> border-radius-3

`}</code></pre>
    <h3 {...{
      "id": "layout",
      "style": {
        "position": "relative"
      }
    }}>{`Layout`}</h3>
    <pre><code parentName="pre" {...{}}>{`
layout-01 -> space-5 layout-02 -> space-6 layout-03 -> space-7

layout-04 -> space-9 layout-05 -> space-10 layout-06 -> space-11 layout-07 ->
removed

`}</code></pre>
    <h3 {...{
      "id": "space",
      "style": {
        "position": "relative"
      }
    }}>{`Space`}</h3>
    <pre><code parentName="pre" {...{}}>{`
space-xx-small -> space-1 space-x-small -> space-2 space-small -> space-3

space-medium -> space-5 space-large -> space-6 space-x-large -> space-7

space-xx-large -> space-9

`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`
space-inset-xx-small -> space-1 space-inset-x-small -> space-2 space-inset-small
-> space-3

space-inset-medium -> space-5 space-inset-large -> space-6 space-inset-x-large
-> space-7

space-inset-xx-large -> space-9

`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`
space-01 -> space-1 space-02 -> space-2 space-03 -> space-3 space-04 -> space-4
space-05 -> space-5 space-06 -> space-6 space-07 -> space-7 space-08 -> space-8
space-09 -> space-9

`}</code></pre>
    <h3 {...{
      "id": "shadow",
      "style": {
        "position": "relative"
      }
    }}>{`Shadow`}</h3>
    <pre><code parentName="pre" {...{}}>{`
shadow-inner -> shadow-inset-1 shadow-none -> shadow-0 shadow-base -> shadow-1
shadow-floating -> shadow-2 shadow-raised -> shadow-3 shadow-selected ->
shadow-4

`}</code></pre>
    <h3 {...{
      "id": "input",
      "style": {
        "position": "relative"
      }
    }}>{`Input`}</h3>
    <pre><code parentName="pre" {...{}}>{`
input-hover-border-color -> alias-field-border-color-hover

`}</code></pre>
    <h3 {...{
      "id": "button-1",
      "style": {
        "position": "relative"
      }
    }}>{`Button`}</h3>
    <pre><code parentName="pre" {...{}}>{`
button-font-adjustment is removed. We realigned our fonts to not need this
anymore.

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      